@import url(/fonts/walsheim/include.css);
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/* User interaction
 * ========================================================================== */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, 0.5);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

#reply {
  position: relative;
  background: #fff;
}

@media (min-width: 500px) {
  #reply {
    margin: 25px auto;
    max-width: 600px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);
  }
}

header {
  position: relative;
  background-color: #f3f2f1;
  border-bottom: 1px solid #dfdfdf;
  overflow: hidden;
  padding: 16px;
}

footer {
  border-top: 1px solid #dfdfdf;
  clear: both;
  overflow: hidden;
  padding: 16px 16px 0;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

header h2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 40px;
}

h4 {
  margin: 0 0 16px 0;
}

form {
  padding: 16px 16px 0;
  overflow: hidden;
}

label {
  display: block;
  cursor: pointer;
  margin-bottom: 4px;
}

input[type='text'],
input[type='email'],
textarea,
select {
  border: 1px solid #dfdfdf;
  padding: 8px;
  border-radius: 3px;
  font-family: inherit;
  font-size: inherit;
  margin-bottom: 8px;
}

select {
  background: #fff;
  height: 36px;
  margin-top: 8px;
  margin-right: 8px;
}

input[type='checkbox'] {
  margin: 5px;
}

textarea {
  width: 100%;
  resize: vertical;
}

button {
  color: #333;
  border: 1px solid #bfbfbf;
  box-shadow: inset 0 -1px 0 0 #bfbfbf;
  background-color: #fff;
  padding: 10px;
  transition: background-color 0.2s;
  font-weight: 700;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  min-width: 100px;
}

button ~ button {
  margin-left: 10px;
}

button.btn-primary {
  color: #fff;
  background-color: #5786d4;
  border: 1px solid #5786d4;
  border-bottom-color: #000a8c;
  box-shadow: inset 0 -1px 0 0 #000a8c;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.close {
  position: absolute;
  top: 8px;
  right: 4px;
  font-size: 28px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.close:hover {
  opacity: 1;
}

.left-area,
.right-area {
  float: left;
  margin-bottom: 16px;
}

.left-area {
  width: 60%;
}

.right-area {
  width: 40%;
  padding-left: 16px;
}

.cannedmessage-left-area {
  width: 40%;
}

.cannedmessage-right-area {
  width: 60%;
  padding-left: 16px;
}

@media (max-width: 500px) {
  .left-area,
  .right-area {
    width: 100%;
    float: none;
    padding: 0;
  }
}

.gdpr-text {
  font-size: 12px;
}

.intent-wrapper {
  margin-bottom: 8px;
  line-height: 1.5;
}

.intent-form {
  padding-left: 22px;
}

.intent-disclaimer {
  font-size: 12px;
  opacity: 0.7;
}

.error {
  color: #ba0926;
  margin-bottom: 8px;
}

.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: rgba(82, 168, 236, 0.8);
  box-shadow: 0 0 5px 1px rgba(82, 168, 236, 0.8);
}

.bilbasen-styles {
  font-family: 'GTWalsheimPro';
}

.bilbasen-styles h1,
.bilbasen-styles h2,
.bilbasen-styles h3,
.bilbasen-styles h4 {
  color: #002e5c;
}

.bilbasen-styles h4 {
  font-size: 16px;
}

.bilbasen-styles a {
  color: #ff4b00;
}

.bilbasen-styles button {
  color: #ff4b00;
  border: none;
  box-shadow: none;
}

.bilbasen-styles button.btn-primary {
  color: #fff;
  background-color: #ff4b00;
  border: none;
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.spinner {
  display: inline-block;
  width: 100px;
  height: 100px;
}

.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 16px;
  border: 16px solid #5786d4;
  border-radius: 50%;
  -webkit-animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #5786d4 transparent transparent transparent;
}

.spinner div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.bilbasen-styles .spinner div {
  border-color: #ff4b00 transparent transparent transparent;
}

.error-overlay {
  font-size: 16px;
  background: #fff;
  box-shadow: 0 0 24px 24px #fff;
  line-height: 1.5;
  width: 285px;
}

.error-overlay .error-message {
  font-size: 14px;
  color: #7f7f7f;
  margin-top: 16px;
}

.fake-link {
  text-decoration: underline;
  cursor: pointer;
}

.fake-link:hover {
  text-decoration: none;
}

#canned-messages {
  margin-bottom: 16px;
}

button.canned-message {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  display: inline-block;
  padding: 4px 6px;
  color: #0881fe;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #0881fe;
  margin: 0 8px 8px 0;
}

button.canned-message:active,
button.canned-message:focus {
  box-shadow: 0 0 0 1px #0080ff;
  background-color: #0080ff12;
}

button.canned-message.selected {
  color: #fff;
  background: #0881fe;
}

.grecaptcha-badge {
  visibility: hidden;
  height: 0 !important;
  width: 0 !important;
}

.recaptcha-consent {
  font-size: 10px;
}

#listing-preview {
  display: flex;
  padding: 16px 16px 0;
}

.car-details {
  width: 60%;
  margin: 0 16px;
}

.car-image-container-outer {
  flex: 0 1 40%;
}

.car-image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.car-image {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 25px;
}

