@import-normalize;
@import url(/fonts/walsheim/include.css);

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, 0.5);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

#reply {
  position: relative;
  background: #fff;
}

@media (min-width: 500px) {
  #reply {
    margin: 25px auto;
    max-width: 600px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);
  }
}

header {
  position: relative;
  background-color: #f3f2f1;
  border-bottom: 1px solid #dfdfdf;
  overflow: hidden;
  padding: 16px;
}

footer {
  border-top: 1px solid #dfdfdf;
  clear: both;
  overflow: hidden;
  padding: 16px 16px 0;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

header h2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 40px;
}

h4 {
  margin: 0 0 16px 0;
}

form {
  padding: 16px 16px 0;
  overflow: hidden;
}

label {
  display: block;
  cursor: pointer;
  margin-bottom: 4px;
}

input[type='text'],
input[type='email'],
textarea,
select {
  border: 1px solid #dfdfdf;
  padding: 8px;
  border-radius: 3px;
  font-family: inherit;
  font-size: inherit;
  margin-bottom: 8px;
}

select {
  background: #fff;
  height: 36px;
  margin-top: 8px;
  margin-right: 8px;
}

input[type='checkbox'] {
  margin: 5px;
}

textarea {
  width: 100%;
  resize: vertical;
}

button {
  color: #333;
  border: 1px solid #bfbfbf;
  box-shadow: inset 0 -1px 0 0 #bfbfbf;
  background-color: #fff;
  padding: 10px;
  transition: background-color 0.2s;
  font-weight: 700;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  min-width: 100px;
}

button ~ button {
  margin-left: 10px;
}

button.btn-primary {
  color: #fff;
  background-color: #5786d4;
  border: 1px solid #5786d4;
  border-bottom-color: #000a8c;
  box-shadow: inset 0 -1px 0 0 #000a8c;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.close {
  position: absolute;
  top: 8px;
  right: 4px;
  font-size: 28px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.close:hover {
  opacity: 1;
}

.left-area,
.right-area {
  float: left;
  margin-bottom: 16px;
}

.left-area {
  width: 60%;
}

.right-area {
  width: 40%;
  padding-left: 16px;
}

.cannedmessage-left-area {
  width: 40%;
}

.cannedmessage-right-area {
  width: 60%;
  padding-left: 16px;
}

@media (max-width: 500px) {
  .left-area,
  .right-area {
    width: 100%;
    float: none;
    padding: 0;
  }
}

.gdpr-text {
  font-size: 12px;
}

.intent-wrapper {
  margin-bottom: 8px;
  line-height: 1.5;
}

.intent-form {
  padding-left: 22px;
}

.intent-disclaimer {
  font-size: 12px;
  opacity: 0.7;
}

.error {
  color: #ba0926;
  margin-bottom: 8px;
}

.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: rgba(82, 168, 236, 0.8);
  box-shadow: 0 0 5px 1px rgba(82, 168, 236, 0.8);
}

.bilbasen-styles {
  font-family: 'GTWalsheimPro';
}

.bilbasen-styles h1,
.bilbasen-styles h2,
.bilbasen-styles h3,
.bilbasen-styles h4 {
  color: #002e5c;
}

.bilbasen-styles h4 {
  font-size: 16px;
}

.bilbasen-styles a {
  color: #ff4b00;
}

.bilbasen-styles button {
  color: #ff4b00;
  border: none;
  box-shadow: none;
}

.bilbasen-styles button.btn-primary {
  color: #fff;
  background-color: #ff4b00;
  border: none;
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  display: inline-block;
  width: 100px;
  height: 100px;
}

.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 16px;
  border: 16px solid #5786d4;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #5786d4 transparent transparent transparent;
}

.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bilbasen-styles .spinner div {
  border-color: #ff4b00 transparent transparent transparent;
}

.error-overlay {
  font-size: 16px;
  background: #fff;
  box-shadow: 0 0 24px 24px #fff;
  line-height: 1.5;
  width: 285px;
}

.error-overlay .error-message {
  font-size: 14px;
  color: #7f7f7f;
  margin-top: 16px;
}

.fake-link {
  text-decoration: underline;
  cursor: pointer;
}

.fake-link:hover {
  text-decoration: none;
}

#canned-messages {
  margin-bottom: 16px;
}

button.canned-message {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  display: inline-block;
  padding: 4px 6px;
  color: #0881fe;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #0881fe;
  margin: 0 8px 8px 0;
}

button.canned-message:active,
button.canned-message:focus {
  box-shadow: 0 0 0 1px #0080ff;
  background-color: #0080ff12;
}

button.canned-message.selected {
  color: #fff;
  background: #0881fe;
}
