#listing-preview {
  display: flex;
  padding: 16px 16px 0;
}

.car-details {
  width: 60%;
  margin: 0 16px;
}

.car-image-container-outer {
  flex: 0 1 40%;
}

.car-image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.car-image {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 25px;
}
